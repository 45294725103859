import { Button, Grid,Popover,TextareaAutosize,Typography,ThemeProvider,Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {popoverTheme} from '../../themes';
import ProjectSettings from '../../components/ProjectSettings';
import {setActive,renameProject,deleteProject} from '../../actions/projectActions'
import firebase from '../../firebase'
const db = firebase.firestore();
const projectsRef = db.collection("projects")

function Project(props){
    const [editing, setEditing] = useState(false);
    const [nameInput, setNameInput] = useState(props.project.name);
    const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
        return (
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction = "row" > 
                        <Grid item xl={9} style={{cursor:"pointer"}} onClick={()=>{
                        props.setActive(props.project.id)
                    }}>
                            {editing === true ?
                            <div>
                                <TextareaAutosize 
                                onKeyPress={(e)=>{
                                    if(e.key === "Enter"){
                                        props.renameProject({id:props.project.id,newName:nameInput})
                                        setEditing(false)
                                    }
                                }} 
                                value = {nameInput} 
                                onChange={(e)=>{
                                    setNameInput(e.target.value)
                                }}></TextareaAutosize>
                                <CheckIcon style={{ color:"#03fc1c"}} 
                                onClick={()=>{
                                    props.renameProject({id:props.project.id,newName:nameInput})
                                    setEditing(false)
                                }}/>
                                <ClearIcon style={{ color:"#fc0303"}} onClick={()=>{setEditing(false);setNameInput(props.project.name)}}/>
                            </div>
                            :
                            <Typography
                            variant = "h5"
                            color="secondary"
                            style = {props.projects.activeProject === props.project.id ? {} : {color : "#FFFFFF75"}}
                            >{props.project.name}</Typography>
                            }
                        </Grid>
                        <Grid item xl={3} style={{textAlign:"end"}}>
                            <EditIcon style={{marginRight:"8px"}}color = "secondary" fontSize="large" onClick={()=>{setEditing(true)}}/>
                            <DeleteForeverIcon id={`project_deleteIcon-${props.project.id}`} color = "primary" fontSize="large" onClick={()=>{setDeletePopoverOpen(true)}}/>
                            <ThemeProvider theme={popoverTheme}>
                            <Popover
                            open={deletePopoverOpen}
                            anchorEl={document.getElementById(`project_deleteIcon-${props.project.id}`)}
                            onClose={()=>{
                                setDeletePopoverOpen(false)
                            }}
                             anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                                <Grid container direction = "column">
                                    <Grid item>
                                        <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        >
                                            Are you sure you want to delete the project {props.project.name}?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        >
                                            This is irreversible
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{marginTop:"16px"}}>
                                        <Grid container direction="row">
                                            <Grid item sm={6}  style={{textAlign:"center"}}>
                                                <Button
                                                style={{width:"90%"}}
                                                color = "primary"
                                                variant="contained"
                                                onClick={()=>{
                                                    setDeletePopoverOpen(false);
                                                    props.deleteProject({id : props.project.id})
                                                    projectsRef.doc(props.project.id).get()
                                                    .then((snapshot) =>{
                                                        if(snapshot.exists){
                                                            snapshot.ref.delete({ recursive: true,
                                                                yes: true})
                                                        }
                                                    })
                                                    props.setActive(props.projects.projects[0].id)
                                                }}
                                                >Delete</Button>
                                            </Grid>
                                            <Grid item sm={6} style={{textAlign:"center"}}>
                                                <Button
                                                style={{width:"90%"}}
                                                variant="contained"
                                                color = "secondary"
                                                onClick={()=>{
                                                    setDeletePopoverOpen(false)
                                                }}
                                                >Close</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Popover>
                            </ThemeProvider>
                        </Grid>
                        {props.projects.activeProject === props.project.id
                        ?
                        <Grid item>
                            <ProjectSettings project={props.project}/>
                        </Grid>
                        :
                        <div/>
                        }
                     
                    </Grid>
                        
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"#FFFFFF85",marginBottom:"8px",marginTop:"16px"}}/>
                </Grid>
            </Grid>
        );
}
let mapStateToProps = state => ({
 projects : state.projects
})

let mapDispatchToProps = {
    setActive : setActive,
    renameProject: renameProject,
    deleteProject : deleteProject,
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Project);