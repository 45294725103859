import {combineReducers} from 'redux';
import panoReducer from './panoReducer';
import loginReducer from "./loginReducer";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import ProjectReducer from './ProjectReducer';
import ProductReducer from './ProductReducer';

const rootReducer = combineReducers({
  pano_params : panoReducer,    
  firebase : firebaseReducer,
  firestore : firestoreReducer,
  login : loginReducer,
  projects : ProjectReducer,
  products : ProductReducer,
});

export default rootReducer;

        