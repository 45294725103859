import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PanoInstance from "./container/PanoInstance"
import {Grid} from '@material-ui/core'
import ToolBar from './container/ToolBar';
import firebase from './firebase';
import Products from './container/Products';
import{setActive,setProjects} from './actions/projectActions';
import {setProducts} from './actions/productActions';
import {UPDATEUSER} from './actions/types';
import {updateLogin} from './actions/loginActions';
import LoginOptions from './components/LoginOptions';


const db = firebase.firestore();
const projectsRef = db.collection("projects");

function App(props) {
  const authListener = () =>{
    firebase.auth().onAuthStateChanged(async(user)=>{
      let projects = [];
      props.setProducts([]);
      if(user){
        let userProjectsSnapshot = await projectsRef.where('user','==',user.uid).get()
        if(!userProjectsSnapshot.empty){
          console.log(userProjectsSnapshot)
          userProjectsSnapshot.forEach(doc => {
            projects.push({...doc.data(),id:doc.id});
          });
        }
        props.updateLogin(UPDATEUSER,user)
        props.setProjects(projects)
        props.setActiveProject(projects[projects.length-1].id)
        }else{
          props.updateLogin(UPDATEUSER,"")
        }
    })
  }
 
  useEffect(async()=>{
    await authListener()
  },[])
  return (
    <Grid container>
   
        <Grid item xs={12} sm={6} md={6} lg={4} xl = {3} style={{backgroundColor: "#1F1F1F",overflowX:"hidden",overflowY:"auto",maxHeight:"100vh"}}>     
           <ToolBar/>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={8} xl = {9}>
          {props.login.user === "" || !props.login.user
          ?
          <div style={{height:"100vh"}}>
          <h2 style={{textAlign:"center",color:"#f40000"}}>Log in to use this App</h2>
          <LoginOptions/>
          </div>
          :
          <Grid container direction="column">
              <Grid item style={{backgroundColor:"#1F1F1F"}}>
              </Grid>
              <Grid item>
                <PanoInstance/>
              </Grid>
              <Grid item>
                  <Products/>
              </Grid>
          </Grid>
          }
         
        </Grid>
      </Grid>
  );
}
let mapStateToProps = state => ({
 projects : state.projects,
 instance : state.pano_params.instance,
 login : state.login
})

let mapDispatchToProps = {
  setActiveProject : setActive,
  setProjects : setProjects,
  updateLogin:updateLogin,
  setProducts : setProducts,
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
