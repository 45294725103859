import {UPDATEBASEPATH,UPDATEBASEPATHINPUT, UPDATENODE,UPDATEINSTANCE,BASEPATHUPDATED,UPDATEPANOREADY,UPDATECURRENTNODE} from '../actions/types';
let params = (new URL(window.location.href)).searchParams;
let initialState={
    basepath : params.get('basepath') || "https://inhaus.360ty.cloud/",
    basepath_input : params.get('basepath') || "https://inhaus.360ty.cloud/",
    basepath_updated : false,
    node :  params.get('node') ? parseInt(params.get('node').substring(4,params.get('node').length)) : 45,
    instance : undefined,
    pano_ready : false,
    current_node : params.get('node') ? parseInt(params.get('node').substring(4,params.get('node').length)) : 45,
}
function panoReducer(state = initialState, action){
    switch(action.type){
        case UPDATEBASEPATH:
            return{...state, basepath : action.payload.value}
        case BASEPATHUPDATED:
            return{...state, basepath_updated : action.payload.value}
        case UPDATEBASEPATHINPUT:
            return{...state, basepath_input : action.payload.value}
        case UPDATENODE:
            return{...state, node : action.payload.value}
        case UPDATEINSTANCE:
            return{...state, instance : action.payload.value}
        case UPDATEPANOREADY:
            return{...state, pano_ready : action.payload.value}
        case UPDATECURRENTNODE:
            return{...state, current_node : action.payload.value}
      
        default:
            return state;
    }
  }
  export default panoReducer