import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import {Grid, Button,TextField, ButtonGroup, Divider, Tooltip, Typography} from '@material-ui/core'
import PanoParams from '../../components/PanoParams';
import firebase from '../../firebase';
import {UPDATEBASEPATHINPUT,UPDATEBASEPATH, UPDATENODE} from '../../actions/types';
import {updatePanoParam} from '../../actions/panoParamActions';
import {setProjectVariable} from '../../actions/projectActions';
import {setProducts,setProductsError} from '../../actions/productActions';
import axios from "axios";
import ProjectPanels from '../../container/ProjectPanels';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const db = firebase.firestore();
const projectsRef = db.collection("projects")

function ProjectSettings(props){
    useEffect(()=>{
        setBasepath()
    },[props.projects.activeProject])

    const setBasepath = () =>{
        if(props.instance && props.instance.pano && props.instance.pano.isLoaded){
            props.updatePanoParam(UPDATEBASEPATHINPUT, {value : props.project.basepath});
            if(props.project.basepath !== props.pano_params.basepath){
                props.updatePanoParam(UPDATEBASEPATH, {value : props.project.basepath});
                props.instance.setBasePath(props.project.basepath)
                props.instance.setStartNode(props.project.node)
                props.instance.reload()
            }else{
                props.instance.pano.openNext(`{node${props.project.node}}`)
                props.updatePanoParam(UPDATENODE, {value : parseInt(props.project.node)});
            }
        }
    }
    const getProducts = async() =>{
        if(props.project){
            try{
                let products_res = await axios.get(`${props.project.wp_url}wp-json/wc/v3/products`,{auth: { username: props.project.wp_user, password: props.project.wp_pw }});
                console.log(`fetched data from ${props.project.wp_url}`)
                props.setProducts(products_res.data);
                props.setProductsError("");
            }catch(err){
                props.setProductsError("an error accured while fetching WooCommerce products. Please check the user and password of the projekt: "+err);
            }
        }else{
            console.log("no project found")
        }
    }
    const [saving, setSaving] = useState(false)
    const [copied, setCopied] = useState(false)

    const pushProjectToDb = async() =>{
        let user = firebase.auth().currentUser;
        if(user){
            await projectsRef.doc(props.project.id).set(props.project);
        setSaving(false)
    }
}
        return (
            <Grid container direction = "column">
                <Grid item style={{margin : "4px",padding:"4px",paddingLeft:0,paddingRight:0}}>
                    <Typography 
                    style={{display:"inline-block"}}
                    variant="body1"
                    color="secondary"
                    ><span style={{color:"rgba(255,255,255,0.7)"}}>
                        Project ID:</span> {props.project.id}
                    </Typography>
                    <CopyToClipboard 
                    text={props.project.id}
                    onCopy={()=>{setCopied(true); setTimeout(()=>{setCopied(false)},1500)}}
                    >
                        {
                        copied
                        ?
                            <CheckCircleOutlinedIcon
                            style={{display:"inline-block", paddingLeft:"4px",verticalAlign:"sub",cursor:"pointer",color:"green"}}
                            />
                        :
                            <FileCopyIcon style={{display:"inline-block", paddingLeft:"4px",verticalAlign:"sub",cursor:"pointer"}}
                                color="secondary"
                            />
                        }
                    </CopyToClipboard>
                  
                </Grid>
                <Grid item>
                    <PanoParams/>
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"#FFFFFF50",margin:"8px"}}/>
                </Grid>
                <Grid item>
                <TextField value= {props.project.wp_url || ""} 
                            onChange = {
                                (e)=>{
                                    props.setProjectVariable(props.project.id,"wp_url",e.target.value)
                                }
                            }
                        
                            style={{width:"98%",marginTop:"10px",marginBottom:"8px"}} label="Wordpress URL" variant="outlined"
                />
                <TextField value= {props.project.wp_user || ""} 
                            onChange = {
                                (e)=>{
                                    props.setProjectVariable(props.project.id,"wp_user",e.target.value)
                                }
                            }
                          
                            style={{width:"98%",marginTop:"10px",marginBottom:"8px"}} label="Wordpress Username" variant="outlined"
                />
                <TextField value= {props.project.wp_pw || ""} 
                            onChange = {
                                (e)=>{
                                    props.setProjectVariable(props.project.id,"wp_pw",e.target.value)
                                }
                            }
                            
                            style={{width:"98%",marginTop:"10px",marginBottom:"8px"}} label="Wordpress Password" variant="outlined"
                />
                </Grid>
                <Grid item style={{margin:"8px"}}>
                    <Grid container direction = "row" alignItems="center" justify = "space-evenly">
                        <Grid item>
                            <Button
                            variant = "outlined"
                            color = "primary"
                            onClick={()=>{
                            getProducts();
                            }}
                            >
                                get Products
                            </Button>
                        </Grid>
                        <Grid item>
                            <p style={{color:"#f40000"}}>We recommend using <span><a target="_blank" href="https://de.wordpress.org/plugins/application-passwords/" style={{color:"#FFFFFF"}}>Application Passwords</a></span></p>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Grid item>
                    {props.products.error !== ""
                    ?
                    <Typography
                    variant = "body1"
                    color = "primary"
                    style= {{padding:"8px",paddingTop:0}}
                    >{props.products.error}</Typography>
                    :
                    <div/>
                    }
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"#FFFFFF50",margin:"8px"}}/>
                </Grid>
                <Grid item>
                    <ProjectPanels project = {props.project}/>
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"#FFFFFF50",margin:"8px"}}/>
                </Grid>
                <Grid item style={{marginTop:"16px",textAlign:"center"}}>
                    <ButtonGroup size="large">
                        <Tooltip
                        open={firebase.auth().currentUser ? false : true}
                        title="log in to save your project"
                        arrow
                        >
                            {saving
                            ?
                            <Button
                            variant = "contained"
                            color="secondary"
                            >
                                Saving Project
                            </Button>
                            :
                            <Button
                            onClick = {()=>{
                                setSaving(true)
                                pushProjectToDb()
                            }}
                            variant = "contained"
                            color="primary"
                            >
                                Save Project
                            </Button>
                            }
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>
        );
}
let mapStateToProps = state => ({
  projects : state.projects,
  products : state.products,
  instance : state.pano_params.instance,
  pano_params : state.pano_params
})

let mapDispatchToProps = {
    updatePanoParam:updatePanoParam,
    setProjectVariable : setProjectVariable,
    setProducts : setProducts,
    setProductsError : setProductsError
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectSettings);