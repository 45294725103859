export const UPDATEPROJECTNODE = "UPDATEPROJECTNODE";
export const UPDATEACTIVEPROJECT = "UPDATEACTIVEPROJECT";
export const UPDATEPROJECTBASEPATH = "UPDATEPROJECTBASEPATH";
export const UPDATEBASEPATH = "UPDATEBASEPATH";
export const UPDATEBASEPATHINPUT = "UPDATEBASEPATHINPUT";
export const UPDATENODE = "UPDATENODE";
export const UPDATEINSTANCE = "UPDATEINSTANCE";
export const BASEPATHUPDATED = "BASEPATHUPDATED";
export const UPDATEPANOREADY = "UPDATEPANOREADY";
export const ADDPROJECTS = "ADDPROJECTS";
export const ADDPROJECT = "ADDPROJECT";
export const DELETEPROJECT = "DELETEPROJECT";
export const RENAMEPROJECT = "RENAMEPROJECT";
export const SETPROJECTS = "SETPROJECTS";
export const UPDATELOGINPOPOVERVISIBILITY = "UPDATELOGINPOPOVERVISIBILITY";
export const UPDATEUSER = "UPDATEUSER";
export const UPDATELOGINPOPOVERID = "UPDATELOGINPOPOVERID";
export const UPDATEERRORMESSAGE = "UPDATEERRORMESSAGE";
export const UPDATECURRENTNODE = "UPDATECURRENTNODE";
export const SETPROJECTVAR = "SETPROJECTVAR";
export const SETPRODUCTS = "SETPRODUCTS";
export const SETPRODUCTSERROR = "SETPRODUCTSERROR";
export const ACTIVEPRODUCT = "ACTIVEPRODUCT";
export const ADDPROJECTPANEL = "ADDPROJECTPANEL";
export const REMOVEPROJECTPANEL = "REMOVEPROJECTPANEL";