import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid,TextField, Divider} from '@material-ui/core';
import {updatePanoParam} from '../../actions/panoParamActions';
import { UPDATENODE } from '../../actions/types';
import {setBasepath,setNode} from '../../actions/projectActions';
import TourSearchInput from '../TourSearchInput';
class PanoParams extends Component {
    render() {
        var activeProject = this.props.projects.projects.filter(project => project.id === this.props.projects.activeProject)
        return (
            <Grid container>
                <Grid item xs={12} sm = {12} md={12} lg={12} xl = {12}>
                    <TourSearchInput/>
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"rgba(255,255,255,0.5)"}}/>
                </Grid>
                <Grid item xs = {4} sm={4} md={3} lg={3} xl = {3} style={{height : "100%"}}>
                    <TextField value= {activeProject[0].node}
                     type = "number"
                     onChange = {
                         (event)=>{
                             if(event.target.value){
                                this.props.updatePanoParam(UPDATENODE, {value:parseInt(event.target.value)});
                                this.props.pano_params.instance.pano.openNext('{node'+event.target.value+'}')
                                this.props.setProjectNode({id:this.props.projects.activeProject,value:parseInt(event.target.value)})
                            }
                        }
                     }
                     style={{width:"100%",marginTop:"10px",marginBottom:"8px"}} id="input_node" label="Node" variant="outlined" />
                </Grid>
                    
            </Grid>
        );
    }
}
let mapStateToProps = state => ({
    pano_params: state.pano_params,
    projects : state.projects
});
let mapDispatchToProps = {
    updatePanoParam : updatePanoParam,
    setProjectBasepath : setBasepath,
    setProjectNode : setNode,
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoParams);