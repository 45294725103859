import { Grid, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Project from '../../components/Project';
import {RENAMEPROJECT, DELETEPROJECT, ADDPROJECT} from '../../actions/types'
import {updateProject} from '../../actions/projectActions'
import {getRandomID} from '../../helper';

function Projects(props){
    const [inputValue, setInputValue] = useState("");
 
        return (
            <Grid container direction = "column">
                {props.projects.projects.length !== 0 ? 
                props.projects.projects.map((project, i)=>(
                    <Grid item key={i}>
                        <Project project = {project}/>
                    </Grid>
                ))
            :
            <div/>
            }
            <Grid item>
                <Grid container direction="row">
                    <Grid item sm={6}>
                        <TextField
                        label="Project Name"
                        variant = "outlined"
                        value={inputValue}
                        onChange={(e)=>{
                            setInputValue(e.target.value)
                        }}
                        />
                    </Grid>
                    <Grid item sm={5} style={{marginLeft:"8px",alignSelf:"center"}}>
                        <Button
                        variant = "outlined"
                        color = "primary"
                        onClick={() => {
                            let defaultProject = {
                                name: inputValue !== ""? inputValue : "Products",
                                id : getRandomID(),
                                basepath : "https://inhaus.360ty.cloud/",
                                node: 1,
                                user: props.login.user.uid,
                                panels: []
                            };
                            props.updateProject(ADDPROJECT,{project : defaultProject})
                            setInputValue("")
                        }}
                        >
                        create Project
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        );
}

let mapStateToProps =state => ({
    projects : state.projects,
    login : state.login
})

let mapDispatchToProps = {
    updateProject : updateProject
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Projects);