import { Grid, GridList,GridListTile,TextField, Button } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import {setProducts, setProductsError,setActiveProduct} from '../../actions/productActions'
import Product from '../../components/Product';
import axios from 'axios';
import {addPanelHotspot} from '../../helper';

function Products(props){
    const [project, setProject] = useState();

    useEffect(()=>{
        async function getProject(){
            return new Promise((resolve,reject)=>{
                let count = 0;
                let projectInterval = setInterval(()=>{
                    if(count > 50){
                        clearInterval(projectInterval);
                        resolve(undefined)
                    }
                    var projectIndex = props.projects.projects.findIndex(project => project.id === props.projects.activeProject);
                    if(projectIndex !== -1){
                        clearInterval(projectInterval);
                        setProject(props.projects.projects[projectIndex])
                        resolve(props.projects.projects[projectIndex]);
                    }
                    count++
                },50)
            })
        }
        const getProducts = async() =>{
            return new Promise(async(resolve,reject)=>{
            let productInterval = setInterval(async()=>{
            if(props.projects && props.projects.projects && props.projects.activeProject){
                clearInterval(productInterval);
                try{
                    var projectIndex = props.projects.projects.findIndex(project => project.id === props.projects.activeProject);
                    if(projectIndex === -1){
                        reject("no project with id "+props.projects.activeProject);
                    }
                    let project = props.projects.projects[projectIndex];
                    let products_res = await axios.get(`${project.wp_url}wp-json/wc/v3/products`,{auth: { username: project.wp_user, password: project.wp_pw }});
                    console.log(`fetched data from ${project.wp_url}`)
                    props.setProducts(products_res.data);
                    props.setProductsError("");
                    resolve(products_res.data);
                }catch(err){
                    props.setProductsError(`an error accured while fetching WooCommerce products. Please check the user and password of the project: 
                    ${err}`);
                    props.setProducts([]);
                    resolve([])
                }
            }else{
            }
        },50);
        })
        }
        const createHotspots = async(products) =>{
            return new Promise((resolve,reject)=>{
                let count = 0;
                let panelInterval = setInterval(()=>{
                    if(count > 50){
                        clearInterval(panelInterval);
                        resolve()
                        console.log("failed after 50 tries")
                    }
                    if(props.instance && props.instance.pano && products && products.length !== 0 && props.projects && props.projects.projects && props.projects.activeProject !== "" && props.projects.projects.length !== 0){
                        let index = props.projects.projects.findIndex(project => project.id === props.projects.activeProject);
                        if(index !== -1){
                            clearInterval(panelInterval);
                            props.projects.projects[index].panels.forEach((panel)=>{
                                if(!props.instance.pano.getHotspot(panel.id) && props.instance.pano.getCurrentNode() === `node${panel.node}`){
                                    addPanelHotspot(panel,products,props.instance.pano);
                                }
                            })
                            resolve()
                        }
                    }
                    count++
                },50)
            }) 
        }
        
        (async()=>{
            let proj = await getProject();
            let products = await getProducts();
            await createHotspots( products || props.products.products);
        })()
    },[props.projects.activeProject]);

        return ( 
            <div>
                {
                    props.products.products !== undefined && typeof( props.products.products) === "object"
                    ?
                    <div style={{display: "flex", flexWrap: "wrap", overflow: "hidden"}}>
                        <GridList style={{transform: 'translateZ(0)',flexWrap:"nowrap"}}>
                            {
                            props.products.products.map((product,i) =>(
                                <GridListTile style={{height:"auto",width:"auto",cursor:"pointer"}} key={i} onClick={()=>{
                                    if(props.products.active !== product.id){
                                        props.setActiveProduct(product.id)
                                    }else{
                                        props.setActiveProduct("")
                                    }
                                }}>
                                <Product product = {product}/>
                                </GridListTile>
                            ))
                            }
                        </GridList>
                    </div>
                    :
                    <p>{ props.products.error !== "" }</p>
                }
            </div>
        )
}
let mapStateToProps =state => ({
    projects : state.projects,
    products : state.products,
    instance : state.pano_params.instance
})

let mapDispatchToProps = {
    setProducts:setProducts,
    setProductsError:setProductsError,
    setActiveProduct:setActiveProduct
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Products);