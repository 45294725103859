import {SETPRODUCTS,SETPRODUCTSERROR,ACTIVEPRODUCT} from './types';

export const setProducts = (value) => dispatch =>{
    dispatch(
        {
            type: SETPRODUCTS,
            payload: value
        }
    )
}
export const setProductsError = (value) => dispatch =>{
    dispatch(
        {
            type: SETPRODUCTSERROR,
            payload: value
        }
    )
}
export const setActiveProduct = (value) => dispatch =>{
    dispatch(
        {
            type: ACTIVEPRODUCT,
            payload: value
        }
    )
}