import React, { Component } from 'react';
import { connect } from 'react-redux';
import {UPDATEINSTANCE,BASEPATHUPDATED,UPDATEPANOREADY} from '../../actions/types';
import {updatePanoParam} from '../../actions/panoParamActions';
import {addProductPanel, removeProductPanel} from '../../actions/projectActions';
import {addPanelHotspot, getRandomID} from '../../helper.js';

class PanoInstance extends Component {

    componentDidMount(){
        const createPanoClickListener = (pano) =>{
            pano.$.addEventListener("click",(e)=>{
                    if(e.ctrlKey && this.props.products.active !== ""){
                        let panoDim = pano.$.getBoundingClientRect();
                        console.log(e.clientX, panoDim.x)
                        let mouse = {
                            x : e.clientX - panoDim.x -26,
                            y : e.clientY - panoDim.y -26,
                        }
                        console.log(mouse.x);
                        let angles = pano.getPositionAngles(mouse.x,mouse.y);
                        let node = pano.getCurrentNode();
                        let productID = this.props.products.active;
                        let panel = {
                            id : getRandomID(),
                            node : parseInt(node.substring(4,node.length)),
                            pan : angles.pan * -1,
                            tilt: angles.tilt,
                            productID : productID
                        }
                        console.log(panel)
                        this.props.addProductPanel(this.props.projects.activeProject,panel);
                        addPanelHotspot(panel,this.props.products.products,pano);
                        let hs = pano.getHotspot(panel.id);
                        let hsDiv = hs.div;
                        hsDiv.addEventListener("click",(e)=>{
                            console.log(hsDiv.querySelector("button"))
                            if(hsDiv.querySelector("button")){
                                hsDiv.querySelector("button").remove();

                            }else{
                                let button = document.createElement("button");
                                button.type = "button";
                                button.classList.add("MuiButtonBase-root-247","MuiButton-root-220", "MuiButton-outlined-225" ,"MuiButton-outlinedPrimary-226")
                                let textSpan = document.createElement("span");
                                textSpan.classList.add("MuiButton-label-221");
                                textSpan.innerText = "remove";
                            
                                let rippleSpan = document.createElement("span");
                                rippleSpan.classList.add("MuiTouchRipple-root-256");
                            
                                button.append(textSpan);
                                button.append(rippleSpan);
                            
                                button.addEventListener("click",()=>{
                                    pano.removeHotspot(panel.id)
                                    this.props.removeProductPanel(this.props.projects.activeProject,panel.id)
                                })
                            
                                hsDiv.append(button)
                            }
                        })
                    }
                })
                console.log("listener set")
        }
        this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
        let tour_360ty = new window.Pano_360ty("360ty_container");
    
        tour_360ty.setBasePath(this.props.pano_params.basepath);
        tour_360ty.setStartNode(parseInt(this.props.pano_params.node));
        tour_360ty.setDimensions("100%","18:9");
        tour_360ty.setHorizontalAlignment("center");
        tour_360ty.setSingleImage(false);
        tour_360ty.setShareButtonVisibility(false);
        tour_360ty.setImpressumVisibility(true);
        tour_360ty.setDimensions_tablet("100%","16:9");
        tour_360ty.setHorizontalAlignment_tablet("center");
        tour_360ty.setSingleImage_tablet(false);
        tour_360ty.setShareButtonVisibility_tablet(false);
        tour_360ty.setImpressumVisibility_tablet(false);
        tour_360ty.setDimensions_mobile("100%","16:9");
        tour_360ty.setHorizontalAlignment_mobile("center");
        tour_360ty.setSingleImage_mobile(false);
        tour_360ty.setShareButtonVisibility_mobile(false);
        tour_360ty.setImpressumVisibility_mobile(false);
        tour_360ty.init();
        this.props.updatePanoParam(UPDATEINSTANCE, {value:tour_360ty});
        let pano_ready_interval = setInterval(()=>{
            if(tour_360ty.pano && tour_360ty.pano.getIsLoaded()){
                clearInterval(pano_ready_interval);
                tour_360ty.pano.stopAutorotate();
             
                this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                createPanoClickListener(tour_360ty.pano);
                tour_360ty.pano.addListener("changenode",()=>{
                    if(tour_360ty.pano && this.props.products &&this.props.products.products && this.props.products.products.length !== 0 && this.props.projects && this.props.projects.projects && this.props.projects.activeProject !== "" && this.props.projects.projects.length !== 0){
                        let index = this.props.projects.projects.findIndex(project => project.id === this.props.projects.activeProject);
                        if(index !== -1){
                            this.props.projects.projects[index].panels.forEach((panel)=>{
                                if(!tour_360ty.pano.getHotspot(panel.id) && tour_360ty.pano.getCurrentNode() === `node${panel.node}`){
                                    addPanelHotspot(panel,this.props.products.products,tour_360ty.pano);
                                    let hs = tour_360ty.pano.getHotspot(panel.id);
                                    let hsDiv = hs.div;
                                    hsDiv.addEventListener("click",(e)=>{
                                        console.log(hsDiv.querySelector("button"))
                                        if(hsDiv.querySelector("button")){
                                            hsDiv.querySelector("button").remove();

                                        }else{
                                            let button = document.createElement("button");
                                            button.type = "button";
                                            button.classList.add("MuiButtonBase-root-247","MuiButton-root-220", "MuiButton-outlined-225" ,"MuiButton-outlinedPrimary-226")
                                            let textSpan = document.createElement("span");
                                            textSpan.classList.add("MuiButton-label-221");
                                            textSpan.innerText = "remove";
                                        
                                            let rippleSpan = document.createElement("span");
                                            rippleSpan.classList.add("MuiTouchRipple-root-256");
                                        
                                            button.append(textSpan);
                                            button.append(rippleSpan);
                                        
                                            button.addEventListener("click",()=>{
                                                tour_360ty.pano.removeHotspot(panel.id)
                                                this.props.removeProductPanel(this.props.projects.activeProject,panel.id)
                                            })
                                        
                                            hsDiv.append(button)
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
                tour_360ty.pano.addListener("beforechangenode",()=>{
                    this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
                });
                tour_360ty.pano.addListener("changenode",()=>{
                    this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                    if(this.props.hotspotPanels.forEach){
                        this.props.hotspotPanels.forEach((panel) =>{
                            if(panel.node === tour_360ty.pano.getCurrentNode()){
                                tour_360ty.pano.addHotspot(panel.id,panel.pan,panel.tilt,panel.div);
                            }
                        })
                    }
                });
               
            }
        },10);
        window.tour_360ty = tour_360ty;
    }
    componentDidUpdate(){
        const createPanoClickListener = (pano) =>{
            pano.$.addEventListener("click",(e)=>{
                    if(e.ctrlKey && this.props.products.active !== ""){
                        console.log(e.clientX,e.clientY)
                        console.log(pano.$.getBoundingClientRect())
                    }
                })
                console.log("listener set")
        }
        if(this.props.pano_params.basepath_updated){
            this.props.updatePanoParam(BASEPATHUPDATED, false);
            console.log("update");
            this.props.pano_params.instance.setBasePath(this.props.pano_params.basepath);
            this.props.pano_params.instance.reload();
            this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
            setTimeout(()=>{
                let pano_ready_interval = setInterval(()=>{
                    if(this.props.pano_params.instance.pano && this.props.pano_params.instance.pano.getIsLoaded()){
                        clearInterval(pano_ready_interval);
                        this.props.pano_params.instance.pano.stopAutorotate();
                        this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                        createPanoClickListener(this.props.pano_params.instance.pano);
                        this.props.pano_params.instance.pano.addListener("changenode",()=>{
                            if(this.props.instance && this.props.instance.pano && this.props.products &&this.props.products.products && this.props.products.products.length !== 0 && this.props.projects && this.props.projects.projects && this.props.projects.activeProject !== "" && this.props.projects.projects.length !== 0){
                                let index = this.props.projects.projects.findIndex(project => project.id === this.props.projects.activeProject);
                                if(index !== -1){
                                    this.props.projects.projects[index].panels.forEach((panel)=>{
                                        if(!this.props.instance.pano.getHotspot(panel.id) && this.props.instance.pano.getCurrentNode() === `node${panel.node}`){
                                            addPanelHotspot(panel,this.props.products.products,this.props.instance.pano);
                                            let hs = this.props.instance.pano.getHotspot(panel.id);
                                            let hsDiv = hs.div;
                                            hsDiv.addEventListener("click",(e)=>{
                                                console.log(hsDiv.querySelector("button"))
                                                if(hsDiv.querySelector("button")){
                                                    hsDiv.querySelector("button").remove();

                                                }else{
                                                    let button = document.createElement("button");
                                                    button.type = "button";
                                                    button.classList.add("MuiButtonBase-root-247","MuiButton-root-220", "MuiButton-outlined-225" ,"MuiButton-outlinedPrimary-226")
                                                    let textSpan = document.createElement("span");
                                                    textSpan.classList.add("MuiButton-label-221");
                                                    textSpan.innerText = "remove";
                                                
                                                    let rippleSpan = document.createElement("span");
                                                    rippleSpan.classList.add("MuiTouchRipple-root-256");
                                                
                                                    button.append(textSpan);
                                                    button.append(rippleSpan);
                                                
                                                    button.addEventListener("click",()=>{
                                                        this.props.instance.pano.removeHotspot(panel.id)
                                                        this.props.removeProductPanel(this.props.projects.activeProject,panel.id)
                                                    })
                                                
                                                    hsDiv.append(button)
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                        this.props.pano_params.instance.pano.addListener("beforechangenode",()=>{
                            this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
                        });
                        this.props.pano_params.instance.pano.addListener("changenode",()=>{
                            this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                            console.log(this.props.hotspotPanels)
                            if(this.props.hotspotPanels.forEach){
                                this.props.hotspotPanels.forEach((panel) =>{
                                    if(panel.node === this.props.pano_params.instance.pano.getCurrentNode()){
                                        this.props.pano_params.instance.pano.addHotspot(panel.id,panel.pan,panel.tilt,panel.div);
                                    }
                                })
                            }
                                
                        });
                    }
                },10);
            },150)

        }
    }
    render() {
      
        return (

                <div id ="instance_container" style={{width:"100%"}}>
                    
                        <div data-tour={this.props.pano_params.basepath} id="360ty_container" style={{width:"100%",zIndex:6}}></div>
                </div>
                );
    }
}
let mapStateToProps = state => ({
    pano_params : state.pano_params,
    products : state.products,
    projects : state.projects
});
let mapDispatchToProps = {
    updatePanoParam : updatePanoParam,
    addProductPanel : addProductPanel,
    removeProductPanel : removeProductPanel
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoInstance);