import firebase from './firebase';

function createHotspotDot(){
	let container = document.createElement("div");
	let hs_dot = document.createElement("div");
	hs_dot.classList.add("hotspot_dot");

	let hs_pulse = document.createElement("div");
	hs_pulse.classList.add("hotspot_pulse");

	container.append(hs_dot);
	container.append(hs_pulse);
	return container
}

function createProductHotspot(imgSrc,headline,description,price){
	let hsDiv = document.createElement("div");
	let product_panel = document.createElement("div");
	product_panel.classList.add("product_panel");
	let panel = createPanel(imgSrc,headline,description,price);
	let hsDot = createHotspotDot();

	hsDiv.addEventListener("mouseover",function(){
		product_panel.style.pointerEvents = "auto";
		product_panel.style.opacity = 1;
	}.bind(this))
	hsDiv.addEventListener("mouseleave",function(){
		product_panel.style.opacity = 0;
		setTimeout(function(){product_panel.style.pointerEvents="none"},500)
	}.bind(this))

	product_panel.append(panel);
	hsDiv.append(hsDot);
	hsDiv.append(product_panel);
	return hsDiv;
}
function createPanel(imgSrc,headline,description,price){
    let container = document.createElement("div");
    container.classList.add("product_container");

    if(imgSrc){
        let leftColumn = document.createElement("div");
        leftColumn.classList.add("left-column");
        let img = document.createElement("img");
        img.src= imgSrc;
        leftColumn.append(img);
        container.append(leftColumn)
    }

    let rightColumn = document.createElement("div");
    rightColumn.classList.add("right-column");
    
    let productDescriptionContainer = document.createElement("div");
    productDescriptionContainer.classList.add("product-description");

    if(headline){
        let header = document.createElement("h1");
        header.innerText = headline;
        productDescriptionContainer.append(header);
    }

    if(description){
        let description_p = document.createElement("p");
        description_p.innerHTML = description;
        productDescriptionContainer.append(description_p)
    }

    rightColumn.append(productDescriptionContainer)

    if(price){
       let priceContainer = document.createElement("div");
       priceContainer.classList.add("product-price");
       
       let priceSpan = document.createElement("span");
       priceSpan.innerText = price;

       priceContainer.append(priceSpan);
        rightColumn.append(priceContainer)
    }
    container.append(rightColumn)
    return container;
}
function addRemoveButton(parentDiv,hsID,pano){
   

}
export const addPanelHotspot = (panel,panels,pano) =>{
    let panel_data = panels.filter(pnl => pnl.id === panel.productID)[0];
    let hsDiv = createProductHotspot(panel_data.images[0].src,panel_data.name,panel_data.description,panel_data.price)
    pano.addHotspot(panel.id,panel.pan,panel.tilt,hsDiv);
    pano.addListener("positionchanged",()=>{
                if(pano.getHotspot(panel.id)){
                    let hs = pano.getHotspot(panel.id);
                    let currentPan = pano.getPan();
                    if(currentPan < 0){
                        currentPan = currentPan + 360;
                    }
                    if(hs.pan < 0){
                        hs.pan = hs.pan + 360;
                    }
                    let pan_diff = parseInt(currentPan-hs.pan);
                    if(pan_diff < 0){
                        pan_diff = pan_diff*-1
                    }
                    if(pan_diff <= 75 || pan_diff - 360 >= -75){
                        if(hs.div.style.visibility === "hidden"){
                            hs.div.style.visibility = "";
                        }
                    }
                }
    })
}
export const getRandomID= ()=>{
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

    var length = 10;

    var str = '';
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
}
