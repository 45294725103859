import {REMOVEPROJECTPANEL,ADDPROJECTPANEL,UPDATEPROJECTNODE,UPDATEACTIVEPROJECT,UPDATEPROJECTBASEPATH,RENAMEPROJECT,DELETEPROJECT, SETPROJECTS,SETPROJECTVAR} from './types';
export const updateProject = (type, value) => dispatch => {
    dispatch(
        {
            type: type,
            payload : value
        }
    );      
}
export const setActive = (id) => dispatch =>{
    dispatch(
        {
            type: UPDATEACTIVEPROJECT,
            payload: id
        }
    )
}

export const setBasepath = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTBASEPATH,
            payload: value
        }
    )
}
export const setNode = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTNODE,
            payload: value
        }
    )
}
export const renameProject = (value) => dispatch =>{
    dispatch(
        {
            type: RENAMEPROJECT,
            payload: value
        }
    )
}
export const deleteProject = (value) => dispatch =>{
    dispatch(
        {
            type: DELETEPROJECT,
            payload: value
        }
    )
}
export const setProjects = (value) => dispatch =>{
    dispatch(
        {
            type: SETPROJECTS,
            payload: value
        }
    )
}
export const setProjectVariable = (id,key,value) => dispatch =>{
    dispatch(
        {
            type: SETPROJECTVAR,
            payload: {id:id,key:key,value:value}
        }
    )
}
export const addProductPanel = (projectID,panel) => dispatch =>{
    dispatch(
        {
            type: ADDPROJECTPANEL,
            payload:{id:projectID,panel:panel}
        }
    )
}
export const removeProductPanel= (projectID,panelID) => dispatch =>{
    dispatch(
        {
            type: REMOVEPROJECTPANEL,
            payload:{panelID:panelID,projectID:projectID}
        }
    )
}