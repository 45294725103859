import {SETPRODUCTS, SETPRODUCTSERROR,ACTIVEPRODUCT} from '../actions/types';

let initialState={
    error: "",
    active: "",
    products: []          
}
function ProductReducer(state = initialState, action){
    switch(action.type){
        case SETPRODUCTS:
            return{
                ...state,
                products: action.payload
            }
        case SETPRODUCTSERROR:
            return{
                ...state,
                error: action.payload
            }
        case ACTIVEPRODUCT:
            return{
                ...state,
                active: action.payload
            }
        default:
            return state;
    }
}
export default ProductReducer