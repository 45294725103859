import {REMOVEPROJECTPANEL,ADDPROJECTPANEL,UPDATEPROJECTBASEPATH,ADDPROJECTS,ADDPROJECT,DELETEPROJECT,RENAMEPROJECT,UPDATEACTIVEPROJECT, SETPROJECTS, UPDATEPROJECTNODE,SETPROJECTVAR} from '../actions/types';

let initialState={
    activeProject: "",
    projects:
        [
        ]          
}
function ProjectReducer(state = initialState, action){
    switch(action.type){
        case ADDPROJECT:
        return {
            ...state,
            projects: [...state.projects,action.payload.project]
        }
        case DELETEPROJECT:
            return {...state,projects:  state.projects.filter(project => project.id !== action.payload.id)}

        case RENAMEPROJECT:
            let newProjects2 = [...state.projects];
            newProjects2.forEach((project) => {if(project.id === action.payload.id){project.name = action.payload.newName}})
            return {...state,projects: newProjects2}
     
        case UPDATEACTIVEPROJECT:
            return {...state, activeProject:action.payload}

   
        case ADDPROJECTS:
            return {
                ...state,
                projects: [...state.projects.concat(action.payload)]
            }
        case SETPROJECTS:
            return{
                ...state,
                projects: action.payload
            }
      
        case UPDATEPROJECTBASEPATH:
            let ProjectIndex7 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects7 = [...state.projects];
            newProjects7[ProjectIndex7].basepath = action.payload.value
            return{
                ...state,
                projects:newProjects7
            }
        case UPDATEPROJECTNODE:
            let ProjectIndex8 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects8 = [...state.projects];
            newProjects8[ProjectIndex8].node = action.payload.value
            return{
                ...state,
                projects:newProjects8
            }
        case SETPROJECTVAR:
            let ProjectIndex9 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects9 = [...state.projects];
            newProjects9[ProjectIndex9][action.payload.key] = action.payload.value;
            return{
                ...state,
                projects:newProjects9
            }
        case ADDPROJECTPANEL:
            let ProjectIndex10 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects10 = [...state.projects];
            newProjects10[ProjectIndex10].panels.push(action.payload.panel);
            return{
                ...state,
                projects:newProjects10
            }
        case REMOVEPROJECTPANEL:
            let ProjectIndex11 = state.projects.findIndex(project => project.id === action.payload.projectID);
            let newProjects11 = [...state.projects];
            newProjects11[ProjectIndex11].panels =  newProjects11[ProjectIndex11].panels.filter(panel => panel.id !== action.payload.panelID)
            return{
                ...state,
                projects:newProjects11
            }
        default:
            return state;
    }
}
export default ProjectReducer