import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid, ThemeProvider,Divider} from "@material-ui/core";
import {headerTheme} from '../../themes';
import SharedHeader from '../../components/SharedHeader'
import Projects from '../Projects';

class ToolBar extends Component {
    render() {
        return (
            <ThemeProvider theme={headerTheme}>
             
                <Grid container spacing = {1} direction="column" style={{padding:"16px"}}>
                    <Grid item>
                        <SharedHeader/>
                    </Grid>
                    {this.props.login.user ?
                     <Grid item style={{overflow:"auto"}}>
                        <Projects/>
                    </Grid>
                    :
                    <div/>
                    }
                   
                </Grid>
            </ThemeProvider>
        );
    }
}
let mapStateToProps = state => ({
  login:state.login
});

export default connect(
    mapStateToProps,
)(ToolBar);