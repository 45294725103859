import { Grid,Typography,Button } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import {removeProductPanel} from '../../actions/projectActions';

function ProjectPanel(props){
    const [panelProduct,setPanelProduct] = useState({name:props.panel.id});

    useEffect(()=>{
        if(props.products && props.products.products && props.products.products.length !== 0){
            var panel = props.products.products.filter(product => product.id === props.panel.productID);
            if(panel.length !== 0){
                setPanelProduct(panel[0])
            }
        }
    },[props.products.products])
  
        return(
            <Grid container direction = "row" style={{padding:"4px",paddingLeft:"12px",paddingRight:"12px"}}>
                <Grid item style={{height:"100%"}}>
                    <Grid container direction = "column" >
                        <Grid item>
                            <Typography variant = "subtitle1" color="secondary">
                                {panelProduct.name}
                            </Typography>
                        </Grid>
                        <Grid item style={{color:"rgba(255,255,255,0.6)"}}>
                            <Typography style={{fontSize:"13px"}}>Product ID: {props.panel.productID}</Typography>
                        </Grid>
                        <Grid item style={{color:"rgba(255,255,255,0.6)"}}>
                            <Typography style={{fontSize:"13px"}}>Node: {props.panel.node}</Typography>
                        </Grid>
                        <Grid item style={{color:"rgba(255,255,255,0.6)"}}>
                            <Typography style={{fontSize:"13px"}}>pan: {props.panel.pan.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item style={{color:"rgba(255,255,255,0.6)"}}>
                            <Typography style={{fontSize:"13px"}}>tilt: {props.panel.tilt.toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginLeft:"auto"}}>
                    <Grid container direction = "column"  style={{height:"100%",justifyContent:"space-evenly"}}>
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={()=>{
                                if( props.instance.pano.getCurrentNode() !== `node${props.panel.node}`){
                                    props.instance.pano.openNext(`{node${props.panel.node}}`);
                                }
                                props.instance.pano.setPan(props.panel.pan);
                                props.instance.pano.setTilt(props.panel.tilt);
                            }}>Jump to Hotspot</Button>
                        </Grid>
                        <Grid item>
                            <Button variant = "outlined" color="primary" onClick={()=>{
                                props.removeProductPanel(props.projects.activeProject,props.panel.id);
                                props.instance.pano.removeHotspot(props.panel.id)
                            }}>remove</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        )
  
}
let mapStateToProps =state => ({
    projects : state.projects,
    products : state.products,
    instance : state.pano_params.instance
})

let mapDispatchToProps = {
    removeProductPanel:removeProductPanel
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectPanel);