import { Grid,Typography, Paper,Divider } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ProjectPanel from '../../components/ProjectPanel';

function ProjectPanels(props){
    const [open, setOpen] = useState();
    if(props.project.panels.length !== 0){
        return(
            <Paper style={{backgroundColor:"#2B2B2B"}}>
                <Grid container direction = "column">
                    <Grid item style={{cursor:"pointer",padding:"8px"}} onClick={()=>{setOpen(!open)}}>
                        <Typography
                        variant="h6"
                        color="secondary"
                        style={{display:"inline-block"}}
                        >
                            Product Panels
                        </Typography>
                        {open
                        ?
                            <ArrowDropDownIcon style={{color:"#FFFFFF",display:"inline-block",float:"right"}} fontSize="large"/>
                        :
                            <ArrowLeftIcon style={{color:"#FFFFFF",display:"inline-block",float:"right"}}fontSize="large"/>
                        }
                    </Grid>
                    <Grid item>
                        {open 
                        ? 
                        <Grid container direction ="column" spacing={1}>
                            {
                                props.project.panels.map((panel,i)=>(
                                    <div key={i}>
                                    <Grid item>
                                        <Divider style={{backgroundColor:"#FFFFFF85",margin:"4px",marginTop:"16px"}}/>
                                    </Grid>
                                    <Grid item >
                                        <ProjectPanel panel = {panel}/>
                                    </Grid>
                                    </div>
                                ))
                            }
                        </Grid>
                        :
                        <div/>
                        }
                    </Grid>
                </Grid>
            </Paper>
            )
    }else{
        return(
            <Paper style={{backgroundColor:"#2B2B2B"}}>
                <Typography
                style = {{padding:"8px"}}
                variant="body1"
                color="secondary"
                >Create Panels by selecting (clicking) a Produkt and clicking on the desired Position in the Tour while holding CTRL</Typography>
            </Paper>
            )
    }
  
}
let mapStateToProps =state => ({
    projects : state.projects,

})

let mapDispatchToProps = {

}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectPanels);