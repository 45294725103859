
import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {updateProject} from '../../actions/projectActions'
function Product(props){
    const getCleanText = (strInputCode)=>{
        return strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
    } 
        return ( 
        <Paper style={props.product.id === props.products.active ? {backgroundColor : "#1C1C1C",margin:"8px", marginRight:0, border:"solid 2px #FFFFFF"} : {backgroundColor : "#1C1C1C",margin:"8px", marginRight:0}}>
            <Grid container direction="column" alignItems="center">
                <Grid item style={{width:"100%",padding:"12px",backgroundColor:"#404040"}} >
                    <Grid container direction = "row">
                        <Grid item style={{marginLeft:"auto",marginLeft:"auto"}}>
                            <Typography
                            variant="h5"
                            color = "secondary"
                            style={{textDecoration:"underline"}}
                            >{props.product.name}</Typography>
                        </Grid>
                        <Grid item style={{alignSelf:"center",marginLeft:"auto"}}>
                            <Typography
                            variant="subtitle1"
                            color = "secondary"
                            >{getCleanText(props.product.sku)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{width:"100%",padding:"12px"}}>
                    <Grid container direction = "row">
                        <Grid item>
                            <img src={props.product.images[0].src} style={{maxHeight:"200px",maxWidth:"200px"}}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction = "column" style={{marginLeft:"auto",marginRight:"auto",padding:"8px",paddingBottom:0}}>
                                <Grid item>
                                    <Typography
                                    variant = "h6"
                                    color = "secondary"
                                    style={{textDecoration:"underline"}}
                                    >description</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                    variant = "body1"
                                    color = "secondary"
                                    >{getCleanText(props.product.description)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                    variant = "h6"
                                    color = "secondary"
                                    style={{textDecoration:"underline"}}
                                    >price</Typography>
                                </Grid>
                                <Grid item>
                                    
                                    <Typography
                                    variant = "body1"
                                    color = "secondary"
                                    >{props.product.price}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
        )
}
let mapStateToProps =state => ({
    projects : state.projects,
    products : state.products
})

let mapDispatchToProps = {
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Product);